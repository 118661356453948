<template>
  <section v-if="services.length > 0" class="our-services">
    <b-container>
      <h1 class="text-center my-5 main-heading">
        {{ groupTitle ? groupTitle : $t("title.all_services") }}
      </h1>
      <b-row class="justify-center">
        <b-col
          v-for="(service, index) in services"
          :key="index"
          :xl="isHome ? 3 : 6"
          lg="6"
          cols="12"
        >
          <SpecificServiceCard
            v-if="isSpecific"
            :service="service"
            class="mb-3"
          />
          <CardItem :isHome="isHome" :service="service" v-else class="mb-3" />
        </b-col>
        <b-col
          v-if="!allServices && GlobalServices.length > 4"
          cols="12"
          class="text-center my-5"
        >
          <Button
            class="button button--md button--outline-primary"
            to="/services"
            :text="$t('button.see_all')"
          ></Button>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <section v-else-if="requestChecker" class="our-services">
    <b-container>
      <b-row class="py-5">
        <b-col xl="3" lg="6" sm="6" cols="12" v-for="index in 4" :key="index">
          <content-placeholders :rounded="true" :centered="true">
            <content-placeholders-img style="height: 250px" />
            <content-placeholders-heading />
            <content-placeholders-text :lines="2" />
          </content-placeholders>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

// pass service
export default {
  name: "CardGroup",
  components: {
    CardItem: () => import("../CardItem"),
    SpecificServiceCard: () => import("../SpecificServiceCard")
  },
  props: {
    groupTitle: {
      type: String,
      default: ""
    },
    services: {
      type: Array,
      default: () => []
    },
    allServices: {
      type: Boolean,
      default: false
    },
    isSpecific: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["GlobalServices"])
  },
  mounted() {
    setTimeout(() => {
      this.requestChecker = false;
    }, 10000);
  },
  data() {
    return {
      requestChecker: true
    };
  }
};
</script>

